import { CategoryOutlined, PointOfSale, ReceiptLongOutlined, PendingActionsOutlined } from '@mui/icons-material'
import { Avatar, Button, Collapse, List, ListItemAvatar, ListItemButton, ListItemText, Stack, Step, StepContent, StepLabel, Stepper, useTheme } from '@mui/material'
import { memo, useContext, useEffect, useState } from 'react'
import ProductForm from './ProductForm'
import ShoppingCartContext from './ShoppingCartContext'
import QuoteForm from '../Cotizaciones/QuoteForm'
import WaybillResume from '../Cotizaciones/WaybillResume'
import AddressesDetails from '../Cotizaciones/AddressesDetails'
import QuotesList from '../Cotizaciones/QuotesList'
import DeliveryForm from './DeliveryForm'
import PendingPaysList from './PendingPaysList'
import SaveAddressCheckbox from './SaveAddressCheckbox';

export default function AddItem({ close }) {
  const saveAddress = () => {
    console.log('Dirección guardada con los siguientes datos');
    console.log('Recipient: ' + JSON.stringify(recipient, null, 2));
    setQuoteStep(3)
  };

  const { carts, actualCart, addItem } = useContext(ShoppingCartContext)
  const cart = carts[actualCart]
  const theme = useTheme()
  const userPermissions = JSON.parse(sessionStorage.getItem('permissions'))
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')

  const [actualStep, setActualStep] = useState(userPermissions['role'] === 'Cliente' || userPermissions['role'] === 'SubCliente' ? 'quote' : 'menu')

  const options = [
    { name: 'Recepción de paquetes', value: 'deliveries', icon: <PointOfSale />, action: () => setActualStep('delivery') },
    { name: 'Cotización', value: 'quote', icon: <ReceiptLongOutlined />, action: () => setActualStep('quote') },
    { name: 'Producto', value: 'product', icon: <CategoryOutlined />, action: () => setActualStep('product') },
    { name: 'Tickets pendientes de pago', value: 'pendingPay', icon: <PendingActionsOutlined />, action: () => setActualStep('pendingPay') }
  ]

  // Variables para la cotización
  const [quoteStep, setQuoteStep] = useState(0)
  const [addresses, setAddresses] = useState(undefined)
  const [sender, setSender] = useState({})
  const [hasSenderSelected, setHasSenderSelected] = useState(false)
  const [recipient, setRecipient] = useState({})
  const [hasRecipientSelected, setHasRecipientSelected] = useState(false)
  const [packet, setPacket] = useState({ esSobre: 1, alto: 1, ancho: 1, largo: 1, peso: 1 })
  const [shipment, setShipment] = useState({ quienPaga: 0, mercancia: 0 })
  const [finalPrice, setFinalPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [irregular, setIrregular] = useState(0)

  const [validatedForm, setValidatedForm] = useState(false)
  const [validatedAddresses, setValidatedAddresses] = useState(false)
  const [validatedWaybill, setValidatedWaybill] = useState(false)

  // Variables para los productos
  const [waybills, setWaybills] = useState([])
  const [product, setProduct] = useState({ name: '', waybill: null, description: '', quantity: 0, amount: 0, pago_pendiente: 0, total: 0 })

  const [validatedProduct, setValidatedProduct] = useState(false)

  // Variables para las entregas
  const [carriers, setCarriers] = useState(undefined)
  const [viewOverweight, setViewOverweight] = useState('')
  const [viewMerchandiseValue, setViewMerchandiseValue] = useState('') 
  const [delivery, setDelivery] = useState({ numero: '', origenEmpresa: '', destinoEmpresa: '', origenPersona: '', destinoPersona: '', origenCP: '', destinoCP: '', origenPais: 'Mexico', destinoPais: 'Mexico', kg: 0, ancho: 0, alto: 0, largo: 0, valorDeMercancia: 0, carrier: undefined, service: undefined, costoDeEnvio: 0, precio_final: 0, esSobre: 1, primaSeguro: 0, tarifaBase: 0, sobrepeso: 0, reexpedicion: 0, irregular: 0, cod: 0, otros: 0, pago_en_destino: 0 })

  const [validatedDelivery, setValidatedDelivery] = useState(false)

  function addProductHandler() {
    addItem('products', product)
    close()
  }

  function addQuoteHandler() {
    const waybill = {
      tipo_servicio_id: shipment.servicio_id,
      tipo_servicio_nombre: shipment.servicio_nombre,
      kg: packet.peso,
      alto: packet.alto,
      ancho: packet.ancho,
      largo: packet.largo,
      irregular: irregular,
      irregularAmount: irregular ? 180.00 : 0,
      origenEmpresa: sender.empresa,
      origenPersona: `${sender.nombre} ${sender.apellidoPaterno} ${sender.apellidoMaterno ?? ''}`.trim(),
      origenCalle: sender.calle,
      origenNumExt: sender.numeroExterior,
      origenNumInt: sender.numeroInterior,
      origenColonia: sender.colonia,
      origenMunicipio: sender.municipio,
      origenEstado: sender.estado,
      origenPais: sender.pais,
      origenCP: sender.codigoPostal,
      origenTelefono1: sender.telefono1,
      origenTelefono2: sender.telefono2,
      origenRef: sender.referencia,
      destinoEmpresa: recipient.empresa,
      destinoPersona: `${recipient.nombre} ${recipient.apellidoPaterno} ${recipient.apellidoMaterno ?? ''}`.trim(),
      destinoCalle: recipient.calle,
      destinoNumExt: recipient.numeroExterior,
      destinoNumInt: recipient.numeroInterior,
      destinoColonia: recipient.colonia,
      destinoMunicipio: recipient.municipio,
      destinoEstado: recipient.estado,
      destinoPais: recipient.pais,
      destinoCP: recipient.codigoPostal,
      destinoTelefono1: recipient.telefono1,
      destinoTelefono2: recipient.telefono2,
      destinoRef: recipient.referencia,
      costoDeEnvio: Number(shipment.costo),
      valorDeMercancia: Number(shipment.mercancia),
      montoACobrar: shipment.quienPaga === 1 ? Number((Number(finalPrice) + Number(shipment.mercancia)).toFixed(2)) : 0,
      precio_final: Number(finalPrice),
      pago_en_destino: shipment.quienPaga,
      origenDestino: shipment.quienPaga === 1 ? 'Destino' : 'Origen',
      eta: shipment.eta,
      generadoPorUsuario: 1,
      service_code: shipment?.service_code,
      proveedor: shipment?.proveedor,
      esSobre: packet.esSobre,
      rate: shipment?.rate,
      quantity: quantity,
      paquete_contratado_uuid: shipment.paquete_contratado_uuid
    }

    addItem('waybills', waybill)
    close()
  }

  function addDeliveryHandler() {
    addItem('deliveries', delivery)
    close()
  }

  function addPendingPayHandler(pendingPay) {
    addItem('pendingPays', pendingPay)
    close()
  }

  async function getAddresses() {
    try {
      setAddresses(undefined)
      const request = await fetch(`${localhost}/api/v2/addresses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (request?.ok) {
        const response = await request.json()
        setAddresses(response.data)
        return
      }

      setAddresses(null)
    } catch (error) {
      console.error('Get addresses', error)
      setAddresses(null)
    }
  }

  async function getCarriers() {
    try {
      setCarriers(undefined)
      const request = await fetch(`${localhost}/api/v2/carriers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (request.ok) {
        const response = await request.json()
        setCarriers(response.carriers)
        return
      }

      setCarriers(null)
    } catch (error) {
      console.error('Get carriers', error)
      setCarriers(null)
    }
  }

  useEffect(() => {
    if (addresses === undefined) {
      getAddresses()
    }

    if (carriers === undefined) {
      getCarriers()
    }
  }, [])

  useEffect(() => {
    const waybillsFromQuotes = cart.waybills?.map(waybill => ({ name: `${waybill.origenPersona} > ${waybill.destinoPersona}`, id: waybill.stamp }))
    const waybillsFromDeliveries = cart.deliveries?.map(waybill => waybill.numero)

    setWaybills([...waybillsFromQuotes, ...waybillsFromDeliveries])
  }, [])

  return (
    <>
      <Collapse in={actualStep === 'menu'}>
        {(cart.waybills?.length !== undefined && cart.deliveries?.length !== undefined && cart.products?.length !== undefined && cart.pendingPays?.length !== undefined)?
          <>
              <Stack>
                  <List sx={{ width: 300 }}>
                    {options.map(option => {
                      if(
                        (cart.waybills.length === 0 && cart.deliveries.length === 0 && cart.products.length === 0 && cart.pendingPays.length === 0) || 
                        (
                          ((cart.waybills.length > 0 || cart.deliveries.length > 0 || cart.products.length > 0) && option.value !== 'pendingPay') || 
                          (cart.pendingPays?.length > 0 && option.value === "pendingPay")
                        )
                      ) {
                        return ( 
                          <ListItemButton 
                            onClick={() => option.action()}
                          >
                            <ListItemAvatar>
                              <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>{option.icon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={option.name} />
                          </ListItemButton>
                        )
                      }

                      return (<></>)
                    })}
                  </List>
              </Stack>

              <ButtonArea>
                <Button
                  variant='contained'
                  onClick={() => {
                    if (window.confirm('¿Esta seguro(a) en salir?')) close()
                  }}
                >
                  Cancelar
                </Button>
              </ButtonArea>
          </>:<>
            <Stack>
                Error al cargar porfavor cierra sesion y vuelve a ingresar usuario y contraseña para continuar
            </Stack>
            <ButtonArea>
              <Button
                variant='contained'
                onClick={() => {
                  sessionStorage.removeItem('token')
                  sessionStorage.removeItem('user')
                  sessionStorage.removeItem('permissions')
                  localStorage.removeItem('sessions')
                  window.location.reload()
                }}
              >
                Cerrar Sesion
              </Button>
            </ButtonArea>
          </>}
      </Collapse>

      <Collapse in={actualStep === 'quote'}>
        {actualStep === 'quote' && (
          <Stepper
            orientation='vertical'
            activeStep={quoteStep}
          >
            <Step key='Form'>
              <StepContainer title='Detalles del envío'>
                <QuoteForm
                  addresses={addresses}
                  getAddresses={getAddresses}
                  recipient={recipient}
                  setRecipient={setRecipient}
                  hasRecipientSelected={hasRecipientSelected}
                  setHasRecipientSelected={setHasRecipientSelected}
                  sender={sender}
                  setSender={setSender}
                  hasSenderSelected={hasSenderSelected}
                  setHasSenderSelected={setHasSenderSelected}
                  packet={packet}
                  setPacket={setPacket}
                  shipment={shipment}
                  setShipment={setShipment}
                  setValidatedForm={setValidatedForm}
                  irregular={irregular}
                  setIrregular={setIrregular}
                />

                <ButtonArea>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      if ((userPermissions['role'] === 'Cliente' || userPermissions['role'] === 'SubCliente') && window.confirm('¿Esta seguro(a) en salir?')) close()
                      if (userPermissions['role'] !== 'Cliente' || userPermissions['role'] !== 'SubCliente') setActualStep('menu')
                    }}
                  >
                    {userPermissions['role'] === 'Cliente' || userPermissions['role'] === 'SubCliente' ? 'Cancelar' : 'Atrás'}
                  </Button>
                  <Button
                    disabled={!validatedForm}
                    variant='contained'
                    onClick={() => setQuoteStep(1)}
                  >
                    Siguiente
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>

            <Step key='Quotes'>
              <StepContainer title='Cotizaciones'>
                <QuotesList
                  actualStep={quoteStep}
                  setActualStep={setQuoteStep}
                  sender={sender}
                  recipient={recipient}
                  packet={packet}
                  shipment={shipment}
                  setShipment={setShipment}
                  setFinalPrice={setFinalPrice}
                  hasSenderSelected={hasSenderSelected}
                  hasRecipientSelected={hasRecipientSelected}
                  irregular={irregular}
                />

                <ButtonArea>
                  <Button
                    variant='outlined'
                    onClick={() => setQuoteStep(0)}
                  >
                    Atrás
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>

            <Step key='Addresses'>
              <StepContainer title='Detalles de los domicilios'>
                <AddressesDetails
                  actualStep={quoteStep}
                  setActualStep={setQuoteStep}
                  sender={sender}
                  setSender={setSender}
                  recipient={recipient}
                  setRecipient={setRecipient}
                  hasSenderSelected={hasSenderSelected}
                  hasRecipientSelected={hasRecipientSelected}
                  setValidatedAddresses={setValidatedAddresses}
                />

                <ButtonArea>
                <SaveAddressCheckbox
                onSave={saveAddress}
                disabled={!validatedAddresses}
                recipient={recipient}
              />
                  <Button
                    variant='outlined'
                    onClick={() => setQuoteStep(1)}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => setQuoteStep(3)}
                    disabled={!validatedAddresses}
                  >
                    Siguiente
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>

            <Step key='Resume'>
              <StepContainer title='Resumen de la guía'>
                <WaybillResume
                  actualStep={actualStep}
                  setActualStep={setActualStep}
                  sender={sender}
                  recipient={recipient}
                  packet={packet}
                  shipment={shipment}
                  setShipment={setShipment}
                  hasSenderSelected={hasSenderSelected}
                  hasRecipientSelected={hasRecipientSelected}
                  validatedWaybill={validatedWaybill}
                  setValidatedWaybill={setValidatedWaybill}
                  finalPrice={finalPrice}
                  setFinalPrice={setFinalPrice}
                  quantity={quantity}
                  setQuantity={setQuantity}
                />

                <ButtonArea>
                  <Button
                    variant='outlined'
                    onClick={() => setQuoteStep(1)}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant='contained'
                    disabled={(['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) && (finalPrice < shipment?.costo || validatedWaybill === false)) || validatedWaybill === false}
                    onClick={() => addQuoteHandler()}
                  >
                    Agregar al carrito
                  </Button>
                </ButtonArea>
              </StepContainer>
            </Step>
          </Stepper>
        )}
      </Collapse>

      <Collapse in={actualStep === 'product'}>
        {actualStep === 'product' && (
          <>
            <ProductForm
              product={product}
              setProduct={setProduct}
              waybills={waybills}
              validatedProduct={validatedProduct}
              setValidatedProduct={setValidatedProduct}
            />

            <ButtonArea>
              <Button
                variant='outlined'
                onClick={() => setActualStep('menu')}
              >
                Atrás
              </Button>
              <Button
                variant='contained'
                disabled={validatedProduct === false}
                onClick={() => addProductHandler()}
              >
                Agregar al carrito
              </Button>
            </ButtonArea>
          </>
        )}
      </Collapse>

      <Collapse in={actualStep === 'delivery'}>
        {actualStep === 'delivery' && (
          <>
            <DeliveryForm
              viewMerchandiseValue={viewMerchandiseValue}
              setViewMerchandiseValue={setViewMerchandiseValue}
              viewOverweight={viewOverweight}
              setViewOverweight={setViewOverweight}
              delivery={delivery}
              setDelivery={setDelivery}
              carriers={carriers} 
              getCarriers={getCarriers}
              validatedDelivery={validatedDelivery}
              setValidatedDelivery={setValidatedDelivery}
            />

            <ButtonArea>
              <Button
                variant='outlined'
                onClick={() => setActualStep('menu')}
              >
                Atrás
              </Button>
              <Button
                variant='contained'
                disabled={validatedDelivery === false}
                onClick={() => addDeliveryHandler()}
              >
                Agregar al carrito
              </Button>
            </ButtonArea>
          </>
        )}
      </Collapse>

      <Collapse in={actualStep === 'pendingPay'}>
        {actualStep === 'pendingPay' && (
          <>
            <PendingPaysList 
              addPendingPayHandler={addPendingPayHandler}
            />

            <ButtonArea>
              <Button
                variant='outlined'
                onClick={() => setActualStep('menu')}
              >
                Atrás
              </Button>
            </ButtonArea>
          </>
        )}
      </Collapse>
    </>
  )
}

const ButtonArea = ({ children }) => {
  return (
    <Stack
      direction='row'
      justifyContent='end'
      spacing={2}
      sx={{ marginTop: 2, width: '100%' }}
    >
      {children}
    </Stack>
  )
}

const StepContainer = memo(({ title, children }) => {
  return (
    <>
      <StepLabel>{title}</StepLabel>
      <StepContent>{children}</StepContent>
    </>
  )
})