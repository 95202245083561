import { useTheme } from '@emotion/react'
import { DeleteOutline, DownloadOutlined, Inventory2Outlined, LocalShippingOutlined, NearMeOutlined, PaidOutlined, PlaceOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import { Avatar, Button, ButtonBase, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useState } from 'react'

export function QuoteDetails({ waybill }) {
  const permissions = JSON.parse(sessionStorage.getItem('permissions'))

  return (
    <Grid
      container
      spacing={2}
      // sx={{ paddingX: 2, paddingY: 2 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <SectionCard
          title='Origen'
          icon={<PlaceOutlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            <Typography variant='subtitle2'>Contacto</Typography>
            <Typography variant='body1'>
              {waybill.origenEmpresa ? `${waybill.origenEmpresa} - ` : ''}
              {waybill.origenPersona}
            </Typography>
            <Typography variant='body1'>{`${waybill.origenTelefono1 ?? ''} ${waybill.origenTelefono2 ?? ''}`.trim()}</Typography>
          </Stack>

          <Stack>
            <Typography variant='subtitle2'>Domicilio</Typography>
            <Typography variant='body1'>{`${waybill.origenCalle} ${waybill.origenNumExt} ${waybill.origenNumInt ? `int. ${waybill.origenNumInt}` : ''}`.trim()}</Typography>
            <Typography variant='body1'>{`${waybill.origenColonia}, ${waybill.origenMunicipio} ${waybill.origenCP}`.trim()}</Typography>
            <Typography variant='body1'>{`${waybill.origenEstado}, ${waybill.origenPais}`.trim()}</Typography>
          </Stack>

          {waybill.origenRef && (
            <Stack>
              <Typography variant='subtitle2'>Referencia</Typography>
              <Typography variant='body1'>{`${waybill.origenRef}`.trim()}</Typography>
            </Stack>
          )}
        </SectionCard>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
      >
        <SectionCard
          title='Destino'
          icon={<NearMeOutlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            <Typography variant='subtitle2'>Contacto</Typography>
            <Typography variant='body1'>
              {waybill.destinoEmpresa ? `${waybill.destinoEmpresa} - ` : ''}
              {waybill.destinoPersona}
            </Typography>
            <Typography variant='body1'>{`${waybill.destinoTelefono1 ?? ''} ${waybill.destinoTelefono2 ?? ''}`.trim()}</Typography>
          </Stack>

          <Stack>
            <Typography variant='subtitle2'>Domicilio</Typography>
            <Typography variant='body1'>{`${waybill.destinoCalle} ${waybill.destinoNumExt} ${waybill.destinoNumInt ? `int. ${waybill.destinoNumInt}` : ''}`.trim()}</Typography>
            <Typography variant='body1'>{`${waybill.destinoColonia}, ${waybill.destinoMunicipio} ${waybill.destinoCP}`.trim()}</Typography>
            <Typography variant='body1'>{`${waybill.destinoEstado}, ${waybill.destinoPais}`.trim()}</Typography>
          </Stack>

          {waybill.destinoRef && (
            <Stack>
              <Typography variant='subtitle2'>Referencia</Typography>
              <Typography variant='body1'>{`${waybill.destinoRef}`.trim()}</Typography>
            </Stack>
          )}
        </SectionCard>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
      >
        <SectionCard
          title='Paquete'
          icon={<Inventory2Outlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            {
              {
                0: (
                  <>
                    <Typography variant='body1'>
                      Dimensiones: {waybill.alto} x {waybill.ancho} x {waybill.largo} cm
                    </Typography>
                    <Typography variant='body1'>Peso: {waybill.kg} kg</Typography>
                    {waybill.irregular && <Typography variant='body1'>Es irregular.</Typography>}
                  </>
                ),
                1: (
                  <>
                    <Typography variant='body1'>Es un sobre</Typography>
                  </>
                )
              }[waybill.esSobre]
            }
            <Typography variant='body1'>Valor de la mercancía: ${waybill.valorDeMercancia}</Typography>
          </Stack>
        </SectionCard>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
      >
        <SectionCard
          title='Envío'
          icon={<LocalShippingOutlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            <Typography variant='body1'>{waybill.tipo_servicio_nombre}</Typography>
            <Typography variant='body1'>Entrega estimada: {new Date(waybill.eta).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
            <Typography variant='body1'>Pago en {{ 0: 'origen', 1: 'destino' }[waybill.pago_en_destino]}</Typography>
            {/* {permissions['role'] !== 'SubCliente' && <Typography variant='body1'>Precio: ${waybill.precio_final!==undefined?waybill.precio_final:'??'}</Typography>} */}
            {/* {waybill.pago_en_destino === 1 && <Typography variant='body1'>Monto a cobrar ${Number(waybill.costoDeEnvio) + Number(waybill.valorDeMercancia)}</Typography>} */}
          </Stack>
        </SectionCard>
      </Grid>
    </Grid>
  )
}

export function WaybillResume({ waybill }) {
  return (
    <Stack
      sx={{ width: '100%', p: 1 }}
      spacing={1}
    >
      <Stack
        direction='row'
        useFlexGap
        spacing={1}
      >
        <Typography overflow='hidden'>
          {waybill.origenEmpresa ? `${waybill.origenEmpresa} - ` : ''}
          {waybill.origenPersona}
        </Typography>

        <Typography overflow='hidden'>{'>'}</Typography>

        <Typography overflow='hidden'>
          {waybill.destinoEmpresa ? `${waybill.destinoEmpresa} - ` : ''}
          {waybill.destinoPersona}
        </Typography>
      </Stack>

      <Stack
        direction='row'
        flexWrap='wrap'
        useFlexGap
        spacing={1}
      >
        <Chip
          label={`${waybill.destinoMunicipio}, ${waybill.destinoEstado}`}
          variant='outlined'
          icon={<NearMeOutlined />}
        />

        <Chip
          label={waybill.tipo_servicio_nombre}
          variant='outlined'
          icon={<LocalShippingOutlined />}
        />

        <Chip
          label={Number(waybill.costoDeEnvio).toFixed(2).toString()}
          variant='outlined'
          icon={<PaidOutlined />}
        />

        <Chip
          label={`Pago en ${{ 0: 'origen', 1: 'destino' }[waybill.pago_en_destino]}`}
          variant='outlined'
          icon={<ShoppingCartOutlined />}
        />
      </Stack>
    </Stack>
  )
}

export function CollapsibleList({ waybill, index, deleteQuote }) {
  const [showDetails, setShowDetails] = useState(false)
  const [showQuoteDelete, setShowQuoteDelete] = useState(false)

  return (
    <>
      <Stack
        direction='row'
        alignItems='start'
        // flexWrap='wrap'
      >
        <ButtonBase
          sx={{ textAlign: 'left', width: '100%' }}
          onClick={() => setShowDetails(!showDetails)}
        >
          <WaybillResume waybill={waybill} />
        </ButtonBase>

        {deleteQuote !== undefined && (
          <IconButton
            onClick={() => setShowQuoteDelete(true)}
            color='error'
            sx={{ m: 1 }}
          >
            <DeleteOutline />
          </IconButton>
        )}
      </Stack>

      <Collapse in={showDetails}>
        <QuoteDetails waybill={waybill} />
      </Collapse>

      <Dialog
        open={showQuoteDelete}
        onClose={() => setShowQuoteDelete(false)}
      >
        <DialogTitle>Eliminar cotización</DialogTitle>
        <DialogContent>¿Seguro que desea eliminar esta cotización? Esta acción no se puede revertir</DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setShowQuoteDelete(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              deleteQuote(index)
              setShowQuoteDelete(false)
            }}
            color='error'
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export function WaybillItem({ waybill }) {
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')

  const [downloading, setDownloading] = useState(false)

  async function DownloadWaybill(waybill) {
    try {
      setDownloading(true)

      const request = await fetch(`${localhost}waybills/download/${waybill}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (request.ok === true) {
        const blob = await request.blob()
        const url = URL.createObjectURL(blob)
        const anchor = document.createElement('a')

        anchor.href = url
        anchor.download = `${waybill}.pdf`
        anchor.click()

        URL.revokeObjectURL(url)

        return
      }

      throw new Error('Ocurrió un error con la petición')
    } catch (error) {
      console.error(error.message)
    } finally {
      setDownloading(false)
    }
  }

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{ paddingX: 2 }}
    >
      <Typography
        variant='body1'
        sx={{ width: '100%' }}
      >
        {waybill}
      </Typography>
      <IconButton
        disabled={downloading === true}
        onClick={() => DownloadWaybill(waybill)}
      >
        {downloading === true ? <CircularProgress size={20} /> : <DownloadOutlined sx={{ fontSize: 20 }} />}
      </IconButton>
    </Stack>
  )
}

const SectionCard = ({ title, icon, children }) => {
  const theme = useTheme()

  return (
    <Stack spacing={1}>
      <Stack
        direction='row'
        spacing={1}
        alignContent='center'
      >
        <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
        <Typography variant='subtitle1'>{title}</Typography>
      </Stack>

      {children}
    </Stack>
  )
}
