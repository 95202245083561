/* Importaciones de React */
import React, { useState, useEffect } from 'react'

/* Importaciones de MUI */
import { Stack, Typography, TextField, Button } from '@mui/material'

/* Importaciones del modulo de Shared */
import { ModalView } from '../../Shared'

import { useNavigate } from 'react-router-dom'

export default function CancelTicketForm({ displayState, ticket }){
    const navigation = useNavigate()

    const localhost = sessionStorage.getItem('localhost')
    const token = sessionStorage.getItem('token')
    
    const [show, setShow] = displayState;
    const [penalty, setPenalty] = useState(0);
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPenalty(parseFloat((ticket?.monto * 0.3).toFixed(2)));
    }, [ticket]);

    async function cancelTicketPost() {
        try {
            setLoading(true)
            const request = await fetch(`${localhost}/api/v2/tickets/cancel`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    uuid: ticket.uuid,
                    penalty: penalty,
                    reason: reason
                })
            })
    
          if (request.ok) {
            const response = await request.json()
            console.log(response)
          } else {
            switch (request.status) {
              case 402:
                throw new Error(`No podemos validar tu pago, inténtalo más tarde, si el problema continua, escríbenos al correo ${process.env.REACT_APP_SUPPORT_EMAIL}, o marca al teléfono ${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}`)
    
              default:
                throw new Error('Ocurrió un error al generar la guía, inténtelo nuevamente más tarde')
            }
          }
        } catch (error) {
          console.error(error)
          //setErrorMessage(error.message)
        } finally {
            setLoading(false)
            setShow(false)
            navigation(-1)
        }
      }
    return (
        <ModalView
            displayState={displayState}
            width='32%'
            maxHeight='85%'
        >
            <Stack
                direction='column'
                spacing={2}
            >
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'center'
                    }}
                >
                    {`¿Esta seguro(a) en cancelar este ticket: ${ticket?.folio} - ${ticket?.uuid}?`}
                </Typography>
            </Stack>
            <Stack
                direction='column'
                spacing={2}
                sx={{ marginTop: '16px' }}
            >
                <TextField
                    label="Monto de penalización"
                    variant="outlined"
                    fullWidth
                    sx={{ fontSize: '12.4px', marginTop: '8px' }}
                    type="number"
                    inputProps={{ step: "0.01" }}
                    value={penalty}
                    onChange={(e) => setPenalty(e.target.value)}
                />
                <TextField
                    label="Motivo de cancelación"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ fontSize: '12.4px' }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
            </Stack>

            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                spacing={2}
                sx={{ marginTop: '16px' }}
            >
                <Button
                    onClick={() => cancelTicketPost()}
                    variant='outlined'
                    sx={{ fontSize: '12.4px' }}
                    disabled={loading}
                >
                    Si, Cancelar ticket
                </Button>

                <Button
                    onClick={() => setShow(false)}
                    sx={{ fontSize: '12.4px' }}
                >
                    Cerrar
                </Button>
            </Stack>
        </ModalView>
    )
}