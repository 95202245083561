import { LocalShippingOutlined, SignalWifiConnectedNoInternet4Outlined } from '@mui/icons-material'
import { Avatar, Button, ButtonBase, Grid, Paper, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

export default function QuotesList({ setActualStep, sender, recipient, packet, shipment, setShipment, setFinalPrice, hasSenderSelected, hasRecipientSelected, irregular }) {
  const theme = useTheme()
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')
  const permissions = JSON.parse(sessionStorage.getItem('permissions'))

  const [quotes, setQuotes] = useState(undefined)

  async function getQuotes() {
    try {
      setQuotes(undefined)

      const request = await fetch(`${localhost}/api/v2/quotes?origen=${sender.codigoPostal}&destino=${recipient.codigoPostal}&esSobre=${packet.esSobre}&peso=${Number(packet.peso)}&alto=${Number(packet.alto)}&largo=${Number(packet.largo)}&ancho=${Number(packet.ancho)}&irregular=${irregular}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (request?.ok) {
        const response = await request.json()
        setQuotes(response?.data)
        return
      }

      throw new Error(`${request.status} - ${request.statusText}`)
    } catch (error) {
      console.error(error)
      setQuotes(null)
    }
  }

  useEffect(() => {
    getQuotes()
  }, [])

  if (quotes === null) {
    return (
      <Stack
        alignItems='center'
        spacing={1}
      >
        <SignalWifiConnectedNoInternet4Outlined sx={{ fontSize: 40 }} />
        <Typography>Tenemos problemas para obtener las cotizaciones</Typography>
        <Button
          variant='outlined'
          onClick={() => getQuotes()}
        >
          Volver a intentar
        </Button>
      </Stack>
    )
  }

  if (quotes?.length > 0) {
    return (
      <Stack
        spacing={2}
        useFlexGap
      >
        {quotes.map((item, index) => (
          <Paper
            key={index.toString()}
            variant='outlined'
            sx={{ overflow: 'hidden' }}
          >
            <ButtonBase
              sx={{ width: '100%', textAlign: 'left', p: 2 }}
              onClick={() => {
                setShipment({
                  ...shipment,
                  servicio_id: item.servicio_id,
                  costo: item.costo,
                  eta: item.fecha_entrega_estimada,
                  servicio_nombre: item.servicio_nombre,
                  proveedor: item.proveedor,
                  service_code: item?.service_code,
                  rate: item?.rate,
                  paquete_contratado_uuid: item.paquete_contratado ? item.paquete_contratado.ticket_uuid : null
                })
                setFinalPrice(Number(item.costo))

                if (hasSenderSelected === true && hasRecipientSelected === true) {
                  setActualStep(3)
                } else {
                  setActualStep(2)
                }
              }}
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  {item.logo ? (
                    <img
                      alt={`Logotipo de ${item.proveedor}`}
                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                      src={item.logo}
                    />
                  ) : (
                    <Paper
                      variant='outlined'
                      sx={{ height: '100%', width: '100%', p: 2 }}
                    >
                      <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <Typography
                          variant='body1'
                          sx={{ textAlign: 'center' }}
                        >
                          Proveedor
                        </Typography>
                        <Typography
                          variant='h2'
                          sx={{ textAlign: 'center' }}
                        >
                          {item.proveedor}
                        </Typography>
                      </Stack>
                    </Paper>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={8}
                >
                  <Stack
                    spacing={2}
                    justifyItems='start'
                    alignItems='start'
                  >
                    <Stack
                      direction='row'
                      spacing={2}
                      useFlexGap
                      flexWrap='wrap'
                    >
                      <Avatar sx={{ width: 30, height: 30, backgroundColor: item.dias_de_entrega.find(item => item === 'Domingo') ? item.paquete_contratado ? theme.palette.primary.main : '#fc9d79' : null }}>D</Avatar>
                      <Avatar sx={{ width: 30, height: 30, backgroundColor: item.dias_de_entrega.find(item => item === 'Lunes') ? item.paquete_contratado ? theme.palette.primary.main : '#fc9d79' : null }}>L</Avatar>
                      <Avatar sx={{ width: 30, height: 30, backgroundColor: item.dias_de_entrega.find(item => item === 'Martes') ? item.paquete_contratado ? theme.palette.primary.main : '#fc9d79' : null }}>M</Avatar>
                      <Avatar sx={{ width: 30, height: 30, backgroundColor: item.dias_de_entrega.find(item => item === 'Miércoles') ? item.paquete_contratado ? theme.palette.primary.main : '#fc9d79' : null }}>Mi</Avatar>
                      <Avatar sx={{ width: 30, height: 30, backgroundColor: item.dias_de_entrega.find(item => item === 'Jueves') ? item.paquete_contratado ? theme.palette.primary.main : '#fc9d79' : null }}>J</Avatar>
                      <Avatar sx={{ width: 30, height: 30, backgroundColor: item.dias_de_entrega.find(item => item === 'Viernes') ? item.paquete_contratado ? theme.palette.primary.main : '#fc9d79' : null }}>V</Avatar>
                      <Avatar sx={{ width: 30, height: 30, backgroundColor: item.dias_de_entrega.find(item => item === 'Sábado') ? item.paquete_contratado ? theme.palette.primary.main : '#fc9d79' : null }}>S</Avatar>
                    </Stack>

                    <Stack>
                      <Typography variant='h2'>{item.servicio_nombre}</Typography>

                      {permissions['role'] !== 'SubCliente' && (
                        <Stack
                          direction='row'
                          alignItems='center'
                          marginTop={1}
                          spacing={0.6}
                        >
                          <Stack spacing={1}>
                            <Typography
                              fontSize='13px'
                              fontWeight='500'
                            >
                              <span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>C</span>osto <span style={{color:  item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>d</span>e<br/><span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>e</span>nvio<span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>:</span>
                            </Typography>

                            <Typography
                              variant='h2'
                              fontWeight='light'
                              fontSize={24}
                            >
                              ${(Number(item.tarifa_base) + Number(item.sobrepeso)).toFixed(2)}
                            </Typography>
                          </Stack>

                          <Typography
                            variant='h2'
                            fontWeight='light'
                            fontSize={24}
                            color={item.paquete_contratado ? '#ff4500' : '#fc9d79'}
                          >
                            +
                          </Typography>

                          <Stack spacing={1}>
                            <Typography
                              fontSize='13px'
                              fontWeight='500'
                            >
                              <span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>C</span>argos <br/><span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>a</span>dicionales<span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>:</span>
                            </Typography>

                            <Typography
                              variant='h2'
                              fontWeight='light'
                              fontSize={24}
                            >
                              ${Number(item.adicionales).toFixed(2)}
                            </Typography>
                          </Stack>

                          {Number(item.irregular) ? <>
                              <Typography
                                variant='h2'
                                fontWeight='light'
                                fontSize={24}
                                color={item.paquete_contratado ? '#ff4500' : '#fc9d79'}
                              >
                                +
                              </Typography>

                              <Stack spacing={1}>
                                <Typography
                                  fontSize='13px'
                                  fontWeight='500'
                                >
                                  <span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>I</span>rregular<span style={{color: item.paquete_contratado ? '#ff4500' : '#fc9d79'}}>:</span>
                                </Typography>

                                <Typography
                                  variant='h2'
                                  fontWeight='light'
                                  fontSize={24}
                                >
                                  ${Number(item.irregularAmount).toFixed(2)}
                                </Typography>
                              </Stack>
                            </> : (
                              <Stack
                                sx={{
                                  marginTop: 1, 
                                  bgcolor: item.paquete_contratado ? theme.palette.primary.main : '#fc9d79', 
                                  borderRadius: 2, 
                                  textAlign: 'center', 
                                  p: 1 
                                }}
                                spacing={1}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    bgcolor: item.paquete_contratado ? '#ff8c00' : '#e59e49',
                                    borderRadius: 2,
                                    p: 1
                                  }}
                                >
                                  Total
                                </Typography>
        
                                <Typography
                                  variant='h2'
                                  sx={{
                                    bgcolor: theme.palette.background.default,
                                    borderRadius: 2,
                                    p: 1,
                                    fontWeight: 'light',
                                    fontSize: 24,
                                    color: item.paquete_contratado ? theme.palette.primary.main : '#fc9d79'
                                  }}
                                >
                                  ${Number(item.costo).toFixed(2)}
                                </Typography>
                              </Stack>
                            )
                          }
                        </Stack>
                      )}
                    </Stack>
                    
                    {Number(item.irregular) && permissions['role'] !== 'SubCliente' ?
                      <Stack
                        sx={{
                          width: '100%', 
                          bgcolor: item.paquete_contratado ? theme.palette.primary.main : '#fc9d79', 
                          borderRadius: 2, 
                          textAlign: 'center', 
                          p: 1 
                        }}
                        spacing={1}
                      >
                        <Typography
                          sx={{
                            fontSize: '13px',
                            fontWeight: '500',
                            bgcolor: item.paquete_contratado ? '#ff8c00' : '#e59e49',
                            borderRadius: 2,
                            p: 1
                          }}
                        >
                          Total
                        </Typography>

                        <Typography
                          variant='h2'
                          sx={{
                            bgcolor: theme.palette.background.default,
                            borderRadius: 2,
                            p: 1,
                            fontWeight: 'light',
                            fontSize: 24,
                            color: item.paquete_contratado ? theme.palette.primary.main : '#fc9d79'
                          }}
                        >
                          ${Number(item.costo).toFixed(2)}
                        </Typography>
                      </Stack>
                      : <></>
                    }

                    <Stack
                      sx={{
                        width: '100%', 
                        bgcolor: item.paquete_contratado ? theme.palette.primary.main : '#fc9d79', 
                        borderRadius: 2, 
                        textAlign: 'center', 
                        p: 1 
                      }}
                      spacing={1}
                    >
                      <Typography 
                        sx={{
                          fontSize: '13px',
                          fontWeight: '500',
                          bgcolor: item.paquete_contratado ? '#ff8c00' : '#e59e49',
                          borderRadius: 2,
                          p: 1
                        }}
                      >
                        Fecha de entrega estimada
                      </Typography>

                      <Typography 
                        sx={{
                          fontWeight: '400',
                          bgcolor: theme.palette.background.default, 
                          borderRadius: 2, 
                          p: 1 
                        }}
                      >
                        {new Date(item.fecha_entrega_estimada).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </ButtonBase>
          </Paper>
        ))}
      </Stack>
    )
  }

  if (quotes?.length === 0) {
    return (
      <Stack
        alignItems='center'
        spacing={1}
        sx={{ textAlign: 'center' }}
      >
        <LocalShippingOutlined sx={{ fontSize: 40 }} />
        <Typography>No hemos encontrado ninguna cotización para los domicilios que has introducido</Typography>
        <Typography>Puedes ponerte en contacto con nuestro departamento de ventas para solicitar una cotización personalizada</Typography>

        <Stack
          direction='row'
          spacing={2}
        >
          <Button
            variant='outlined'
            href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`}
          >
            Mandar correo
          </Button>
          <Button
            variant='outlined'
            href={`https://wa.me/${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}?text=Quiero realizar una cotización con los siguientes datos:\nCódigo postal de origen: ${sender.codigoPostal}\nCódigo postal de destino: ${recipient.codigoPostal}\nPeso: ${packet.peso ? packet.peso : 0}kg\nAlto: ${packet.alto ? packet.alto : 0}cm\nAncho: ${packet.ancho ? packet.ancho : 0}cm\nLargo: ${packet.largo ? packet.largo : 0}cm`}
            target='_blank'
          >
            Mandar WhatsApp
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <Paper
      variant='outlined'
      sx={{ p: 2 }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Skeleton
            variant='rectangular'
            animation='wave'
            height={'100%'}
            sx={{ minHeight: 100 }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
        >
          <Stack
            spacing={2}
            justifyItems='start'
            alignItems='start'
          >
            <Stack
              direction='row'
              spacing={2}
              // justifyContent='space-between'
              useFlexGap
              flexWrap='wrap'
            >
              <Skeleton
                variant='circular'
                sx={{ width: 30, height: 30 }}
                animation='wave'
              />
              <Skeleton
                variant='circular'
                sx={{ width: 30, height: 30 }}
                animation='wave'
              />
              <Skeleton
                variant='circular'
                sx={{ width: 30, height: 30 }}
                animation='wave'
              />
              <Skeleton
                variant='circular'
                sx={{ width: 30, height: 30 }}
                animation='wave'
              />
              <Skeleton
                variant='circular'
                sx={{ width: 30, height: 30 }}
                animation='wave'
              />
              <Skeleton
                variant='circular'
                sx={{ width: 30, height: 30 }}
                animation='wave'
              />
              <Skeleton
                variant='circular'
                sx={{ width: 30, height: 30 }}
                animation='wave'
              />
            </Stack>

            <Stack>
              <Skeleton
                variant='text'
                animation='wave'
                width={250}
                height={25}
              />
              <Skeleton
                variant='text'
                animation='wave'
                width={100}
                height={30}
              />
            </Stack>

            <Skeleton
              variant='rectangular'
              animation='wave'
              width={150}
              height={50}
            />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}
